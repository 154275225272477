import React, {useState} from 'react';
import MLM from '../contract/MLM';
import MLMDeposit from '../contract/MLMdeposit';
import {ToastContainer, toast} from 'react-toastify';
import {ethers} from 'ethers';
import bigInt from 'big-integer';

export default function Admin() {
  const [address, setAddress] = useState('');
  const [loader, setLoader] = useState(false);
  const [withUsdtAmount, setWithUsdtAmount] = useState('');
  const [usdtAmount, setUsdtAmount] = useState('');
  const [depositWithAmount, setDepositWithAmount] = useState('');

  const handleSubmit = async () => {
    try {
      setLoader(true);
      let _allAdd = address.split(',');
      console.log('🚀 ~ handleSubmit ~ _allAdd', _allAdd);
      let _add = await MLM.addWhiteList(_allAdd);
      let _wait = await _add.wait();
      if (_wait) {
        toast.success('Transaction successful!');
        setLoader(false);
      }
    } catch (error) {
      console.log('🚀 ~ handleSubmit ~ error', error);
      toast.error('Something went wrong!');
      setLoader(false);
    }
  };
  const handleOwnerWithdrawUsdt = async () => {
    try {

      let actualReceivedAmount = (withUsdtAmount * 10 ** 18);
      let value = bigInt(actualReceivedAmount);
      let _add = await MLM.getTokenFromContract(value.toString());
      let _wait = await _add.wait();
      if (_wait) {
        toast.success('Transaction successful!');
      }
    } catch (error) {
      console.log('🚀 ~ handleSubmit ~ error', error);
      toast.error('Something went wrong!');
      setLoader(false);
    }
  };

  const handleWithDespoitToken = async () => {
    try {
      let actualReceivedAmount = (depositWithAmount * 10 ** 18);
      let value = bigInt(actualReceivedAmount);
      let _add = await MLMDeposit.getTokenFromContract(value.toString());
      let _wait = await _add.wait();
      if (_wait) {
        toast.success('Transaction successful!');
      }
    } catch (error) {
      console.log('🚀 ~ handleSubmit ~ error', error);
      toast.error('Something went wrong!');
      setLoader(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container py-4">
        <div className="row d-flex justify-content-center">
          <div className="col-6">
            <h4 className="text-dark">Enter Addresses</h4>
            <div class="form-group">
              <textarea
                class="form-control"
                id="exampleFormControlTextarea3"
                rows="5"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></textarea>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col d-flex justify-content-center">
                {loader ? (
                  <div class="spinner-border text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary my-2"
                  >
                    submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                class="form-label text-dark"
              >
                Withdraw Amount from Withdrawal contract
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="withdraw"
                value={withUsdtAmount}
                onChange={(e) => setWithUsdtAmount(e.target.value)}
              />
              <button
                onClick={handleOwnerWithdrawUsdt}
                className="withdrawButton dashboard mt-2"
              >
                Submit
              </button>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                class="form-label text-dark"
              >
                Withdraw Amount from deposit contract
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="withdraw"
                value={depositWithAmount}
                onChange={(e) => setDepositWithAmount(e.target.value)}
              />
              <button
                onClick={handleWithDespoitToken}
                className="withdrawButton dashboard mt-2"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
