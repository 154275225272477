import React, {useState, useEffect} from 'react';
import MLM from '../contract/MLM';
import TOKEN from '../contract/Token';
import {ToastContainer, toast} from 'react-toastify';
import {providers} from 'ethers';
import bigInt from 'big-integer';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';

export default function Home() {
  const [isOwner, setIsOwner] = useState(false);

  const [userAddress, setUserAddress] = useState('');
  const [withdrawValue, setWithdrawValue] = useState(0);
  const [handleWithdrawLoader, setHandleWithdrawLoader] = useState(false);
  const [userWithdrawBalance, setUserWithdrawBalance] = useState(0);
  const [userValid, setUserValid] = useState(false);
  const [tokenPrice, setTokePrice] = useState(0);
  const [show, setShow] = useState(false);
  const [popUpwithdrawValue, setPopupWithdrawValue] = useState('');
  const [popUpClaimValue, setPopupClaimValue] = useState('');
  const [showDanger, setShowDanger] = useState(false);
  const handleClose = () => setShow(false);
  const [isId, setIsId] = useState(true);
  const [userId, setUserId] = useState('');
  const [digiCoinRate, setDigiCoinRate] = useState();
  const [userWalletBalance, setUserWalletBalance] = useState(0);

  const handleShow = () => {
    setShow(true);

    // getPopUpValue();
  };

  useEffect(() => {
    if (userAddress) {
      getUserWalletBalance();
    }
    return () => {};
  }, [userAddress]);

  const handleWalletConnect = async () => {
    console.log('click');
    // try {
    //   await provider.enable();
    // } catch (error) {}
    if (window.ethereum) {
      window.ethereum
        .request({method: 'eth_requestAccounts'})
        .then(handleAccountsChanged)
        .catch((err) => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
          } else {
            console.error(err);
          }
        });
      return true;
    } else {
      console.log('Please connect to MetaMask.');
      return false;
    }
  };
  function handleAccountsChanged(accounts) {
    let currentAccount;

    if (window.ethereum) {
      if (window.ethereum.networkVersion !== '56') {
        //  toast.error('Please connect to Binance Mainnet');
        //  return toast.error('Please connect to Binance Mainnet');
      }
    }

    if (accounts.length === 0) {
      console.log('🚀 ~ handleAccountsChanged ~ accounts', accounts);
      // MetaMask is locked or the user has not connected any accounts
      // console.log("Please connect to MetaMask.");
    } else if (accounts[0] !== currentAccount) {
      console.log(
        '🚀 ~ handleAccountsChanged ~ currentAccount',
        currentAccount
      );
      currentAccount = accounts[0];
      setUserAddress(currentAccount);

      // Do any other work!
    }
  }
  const getUserTokenBalance = async () => {
    let amount = await TOKEN.balanceOf(userAddress);
    let userWalletAmount = (amount / 10 ** 18).toString();
    setUserWalletBalance(userWalletAmount);
    console.log('User wallet amount', userWalletAmount);
  };

  const handleDigiLiveRate = async () => {
    try {
      let url = `https://libertycoin.io/dashboard/api_root_main/coin_balance.php`;
      let bal = await axios.get(url).then((res, error) => {
        if (res) {
          setDigiCoinRate(res?.data);
          console.log('Live GOLDMINE coin rate', res?.data);
        }
      });
    } catch (error) {
      console.log('handleDigiLiveRate', error);
    }
  };

  useEffect(() => {
    getAdmin();
    getUserTokenBalance();
    return () => {};
  }, [userAddress]);

  useEffect(() => {
    handleDigiLiveRate();
    return () => {};
  }, []);

  const getAdmin = async () => {
    console.log('🚀 ~ getAdmin ~ userAddress', userAddress);
    try {
      if (userAddress) {
        let owner = await MLM.owner();
        console.log('🚀 ~ getAdmin ~ owner', owner);
        console.log('🚀 ~ getAdmin ~ userAddress', userAddress);
        if (userAddress.toLowerCase() == owner.toLowerCase()) {
          console.log('valid');
          setIsOwner(true);
        }
      }
    } catch (error) {
      console.log('🚀 ~ getAdmin ~ error', error);
    }
  };

  const handleWithdraw = async () => {
    handleClose();
    if (!userAddress) {
      return toast.error('Please connect Metamask first.');
    }
    if (withdrawValue < 10) {
      return toast.error('Amount should be greater than 10');
    }
    const isWhitelisted = await MLM._whiteList(userAddress);
    if (!isWhitelisted) {
      toast.error('Network error try after sometime');
      return;
    }

    var withdrawValueNumeric = parseFloat(withdrawValue);
    var userWithdrawBalanceNumeric = parseFloat(userWithdrawBalance);
    if (withdrawValueNumeric > userWithdrawBalanceNumeric) {
      return toast.error('Amount should not be greater than Balance.');
    } else {
      console.log(
        'Condition not met: withdrawValue is not greater than userWithdrawBalance'
      );
    }
    console.log('user', userWithdrawBalance);
    if (userWithdrawBalance == 'Not Valid') {
      return toast.error('Insufficient balance to withdraw!.');
    }
    let url = `https://libertycoin.io/dashboard/api_root_main/redeem_balance.php?address=${userAddress}`;
    let fetchWIthdrawBalanceHalfValue = await axios
      .get(url)
      .then((res, err) => {
        if (err) throw err;
        console.log(res, 'res');
        return res;
      });

    setHandleWithdrawLoader(true);
    try {
      setShowDanger(true);

      console.log(
        '🚀 ~ handleWithdraw ~ fetchWIthdrawBalanceHalfValue',
        fetchWIthdrawBalanceHalfValue
      );
      let _w = fetchWIthdrawBalanceHalfValue.data;
      let w = _w;
      console.log('🚀 ~ handleWithdraw ~ w', w);

      let amount = Math.round(withdrawValue);
      console.log('amount', amount);

      let tenpercent = (amount * 10) / 100;
      let _sendCOntract = amount - tenpercent;
      let actualReceivedAmount = (_sendCOntract / digiCoinRate) * 10 ** 18;
      let actualRealNumber = Math.round(actualReceivedAmount);
      let value = bigInt(actualRealNumber);
      console.log('🚀 ~ handleWithdraw ~ tenpercent', tenpercent);

      let formdata = new FormData();
      formdata.append('address', userAddress);
      formdata.append('amount', withdrawValue);
      // formdata.append('userid', userId);
      let url = `https://libertycoin.io/dashboard/api_root_main/redeem.php`;
      let withdraw1 = await axios.post(url, formdata).then((res, err) => {
        if (res) {
          console.log('🚀 ~ withdraw1 res ~ res', res?.data[1]);
          if (res?.data[1] === 'Status:400') {
            console.log('withdraw1 res ~ res false donw');
            return false;
          }
          // getUserWalletBalance();
          // return res;
        }
        if (err) {
          console.log('🚀 ~ withdraw1 ~ err', err);
          console.log(err);
        }
      });
      console.log(
        '🚀 ~ handleWithdraw ~ withdraw1 withdraw1 res ~ res',
        withdraw1
      );
      if (withdraw1) {
        toast.error('Today Limit Exceed');
        setHandleWithdrawLoader(false);
        setShowDanger(false);

        return null;
      }

      const withdraw = await MLM.withdraw_Liberty_coin(value.toString());

      const waitforTx = await withdraw.wait();
      if (waitforTx) {
        setHandleWithdrawLoader(false);
        toast.success('Withdraw successful.');
        setShowDanger(false);
        getUserWalletBalance();
        getUserTokenBalance();
      }
    } catch (error) {
      console.log(error);
      setHandleWithdrawLoader(false);
      toast.error('Something went wrong.');
      setShowDanger(false);
    }
  };

  const getUserWalletBalance = async () => {
    let url = `https://libertycoin.io/dashboard/api_root_main/redeem_balance.php?address=${userAddress}`;
    let bal = await axios.get(url).then((res, err) => {
      if (err) {
        setUserValid(false);
        console.log('err', err);
      }
      if (res) {
        console.log('userBalance', res);
        setUserValid(true);
        return res;
      }
    });
    let _bal = bal?.data;

    setUserWithdrawBalance(_bal);
  };
  const handleId = () => {
    if (userId) {
      setIsId(!isId);
    } else {
      toast.error('Please enter your id');
    }
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className="container-fluid pt-3">
        <div className="row  ">
          <div className="col-12 col-md-4 col-lg-4 d-flex justify-content-center justify-content-md-start ">
            <a
              className="  dashboard text-white"
              href={`https://libertycoin.io/dashboard/index.php?address=${userAddress}`}
            >
              Dashboard
            </a>
          </div>
          <div className="col-lg-4 col-12 col-md-4  d-flex justify-content-center">
            {isOwner ? (
              <Link
                to={'/admin'}
                className="dashBoard wallet  btn btn-outline border-white text-white withdrawButton dashboard"
              >
                Admin
              </Link>
            ) : (
              ''
            )}
          </div>
          <div className="col-12 col-lg-4 col-md-4 d-flex justify-content-center justify-content-md-end pt-2">
            {userAddress ? (
              <button
                className="dashBoard wallet dashboard"
                // onClick={handleWalletConnect}

                disabled
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '160px',
                  whiteSpace: 'nowrap',
                  color: 'white'
                }}
              >
                {' '}
                {userAddress}
              </button>
            ) : (
              <button
                className="dashBoard wallet2 dashboard"
                onClick={handleWalletConnect}
              >
                {' '}
                Connect Wallet{' '}
              </button>
            )}
          </div>
        </div>
      </div>
      {isId ? (
        <div className="container ">
          <div className="row d-flex justify-content-center mt-5 pt-4">
            <div
              className="col-lg-10 col-md-8   shadow-lg rounded-1 myContent"
              style={{
                backgroundImage: '#010e52;'
              }}
            >
              <div className="col-12 pb-4 ">
                  <div className="col-12 d-grid justify-content-center p-2">
                    <img src="https://libertycoin.io/logo.png" alt="coin" />
                  </div>
                  <h2 className="text-center text-dark pb-4">WITHDRAWAL</h2>

              <div className="row">

                  <div className="col-6 px-0 pt-2">
                  <label htmlFor="input " className="pb-2 text-dark ps-4" >
                      {' '}
                      My Balance
                    </label>
                    <p
                      className="ps-2  border mx-3 py-2 "
                      style={{
                        backgroundColor: '#D9D9D9',
                        borderRadius: '5px',
                        color: '#2f323b ',
                        fontWeight: '500',
                        fontSize: '16px'
                      }}
                    >
                      (My Balance) - ({'$'} {userWithdrawBalance ?? '0'})
                    </p>
                  </div>
                  <div className="col-6 pt-2">
                    <label htmlFor="input " className="pb-2 text-dark">
                      {' '}
                      Enter Amount in USD
                    </label>
                    <input
                      style={{
                        backgroundColor: '#D9D9D9',
                        borderRadius: '5px',
                        color: '#2f323b ',
                        fontWeight: '500',
                        fontSize: '18px'
                      }}
                      className="form-control "
                      type="text"
                      placeholder="Enter USD Value"
                      aria-label="default input example"
                      value={withdrawValue}
                      onChange={(e) => setWithdrawValue(e.target.value)}
                    />
                  </div>
              </div>

              </div>
              <div className="row  mx-2 ">
                <p>
                  Received -{' '}
                  {parseFloat(
                    ((withdrawValue / digiCoinRate) * 90) / 100
                  ).toFixed(2)}
                  {' Liberty Coin'}
                </p>
                <p className="text-dark pt-1" style={{fontSize: '12px'}}>
                  My Wallet Balance : {userWalletBalance} Liberty Coin
                </p>
              </div>

              {userValid ? (
                <div className="row   pb-4">
                  <div className="dashBoard dashBoard2 pt-4 text-center">
                    <>
                      {!handleWithdrawLoader ? (
                        <button
                          className="btn btn-outline border-white text-white withdrawButton dashboard"
                          // onClick={() => handleWithdraw()}
                          onClick={handleShow}
                        >
                          Withdraw
                        </button>
                      ) : (
                        <div class="spinner-border text-success" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="container mt-2 mt-md-5">
            <div className="container ">
              <div className="row d-flex justify-content-center">
                <div
                  className="col-lg-5 col-md-8  p-2 m-2 shadow-lg rounded-1"
                  style={{
                    backgroundColor: '#ffab01'
                  }}
                >
                  <div className="d-flex justify-content-center pb-4">
                    <img src="https://libertycoin.io/logo.png" alt="coin" />
                  </div>
                  <div className="px-3 px-4 py-2">
                    <div class="mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Enter Your Id
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="id"
                        placeholder="Enter Your Id"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      className={`btn btn-outline border-white text-white  withdrawButton dashboard`}
                      onClick={handleId}
                      // onClick={handleShow}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {handleWithdrawLoader ? (
        <div className="alert alert-warning bg-danger text-dark" role="alert">
          Don't refresh the page, otherwise you lost your money.
        </div>
      ) : (
        ''
      )}
      <div className="container text-center mt-3 ps-3">
        <div className="row">
          <div className="col text-black">
            &#169; 2023{' '}
            <span
              style={{
                color: 'black'
              }}
            >
              {' '}
              Liberty Coin{' '}
            </span>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text-dark">Transaction </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-dark">
            Are you sure wanted to process this transaction?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Reject
          </Button>
          <Button variant="primary" onClick={handleWithdraw}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      {showDanger ? (
        <div class="alert alert-danger text-center" role="alert">
          Please don't refresh the page, Otherwise you lost your money.
        </div>
      ) : (
        ''
      )}{' '}
    </>
  );
}
